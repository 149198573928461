define("discourse/plugins/discourse-strudel/initializers/discourse-strudel", ["exports", "discourse/lib/load-script", "discourse/lib/plugin-api"], function (_exports, _loadScript, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "discourse-strudel",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.8.22", api => {
        api.decorateCooked($elem => {
          const $strudel = $elem.find("strudel-repl");
          if ($strudel.length) {
            (0, _loadScript.default)("/plugins/discourse-strudel/javascripts/embed-strudel.js").then(() => {});
          }
        });
      });
    }
  };
});